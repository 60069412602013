import React from "react";
import {Divider, Grid, Header, Icon, Image, Segment, Table} from "semantic-ui-react";
import Bullet1 from "../images/Punkt1_95@4x.png";
import Bullet2 from "../images/Punkt2_96@4x.png";
import Bullet3 from "../images/Punkt3_97@4x.png";
import KlimaBanner from "../images/Letpension_infographic.jpg";
import NidecLogo from "../images/nidec.png";
import RepublicLogo from "../images/Republic_Services_logo.png";
import "./Klima.css"

const Forside = props => (
    <div style={{backgroundColor: 'white'}}>
        <Header as="h2" textAlign="center">Godt nyt til dig, der ønsker at gøre noget ekstra for klimaet</Header>
        <Divider hidden/>
        <Segment basic>
            <p>Din pension er en vigtig brik, hvis du vil gøre noget ekstra for klimaet. Derfor tilbyder Letpension i
                samarbejde med PFA en opsparings&shy;mulighed, som har ekstra fokus på klima.</p>
            <p>De klimavenlige investeringer har samme risikoniveau og forventning til afkast på langt sigt som din
                nuværende opsparing. Derfor skal du blot vælge, hvor stor en andel af din opsparing, der skal have
                ekstra
                fokus på klimaet.</p>
            <p><a href="https://www.pfa.dk/letpension/klima/" target="_blank">Den 1. april 2025 øger PFA ambitionerne i Klima yderligere.</a> </p>

            <h3>Du kan mindske CO<sub>2</sub>-aftrykket med din pension</h3>
            <p>Du kan mindske dit CO<sub>2</sub>-aftryk på mange måder. Du kan spise mindre kød, du kan flyve mindre, du
                kan skifte
                benzinbilen ud med en elbil. Men du kan også påvirke det store CO<sub>2</sub>-regnskab med din pension.
                For hver
                100.000 kr. du lægger i ekstra klimavenlige investeringer, sparer du miljøet for 0,83 Ton
                CO<sub>2</sub> hvert år. En kødfri dag om ugen i et helt år svarer til en besparelse på 95 kg
                CO<sub>2</sub>. Det kan altså hurtigt blive til meget, hvis du flytter en del af din pension til ekstra
                klimavenlige investeringer. (Kilde: PFA, beregning af 31.12.2022)</p>
            <p><a href="https://pfa.dk/andet/disclaimer-klimaplus/" target="_blank" rel="noopener noreferrer"
                  className="link">Sådan beregner PFA
                CO<sub>2</sub></a></p>
        </Segment>
        <Divider hidden/>
        <Segment basic style={{backgroundColor: '#f6f0e7'}}>
            <h3>Tre konkrete klimamål</h3>
            <p>Når PFA investerer pensionsopsparingerne, er det altid baseret på ansvarlighed, bæredygtighed og med udgangspunkt i Paris-aftalen og FN’s Verdensmål. Med Klima kan du tage endnu et skridt for at støtte op om den klimavenlige omstilling, og det er både overskueligt og fleksibelt, for klimamålsætningen er bygget op om tre konkrete klimamål. </p>

            <Grid centered stackable columns="three" divided>
                <Grid.Row>
                    <Grid.Column style={{textAlign: 'center'}}>
                        <div><img src={Bullet1} className="bullet"/></div>
                        <div><b>Aktiedelen skal allerede nu som minimum udlede 60 % mindre CO<sub>2</sub> end
                            verdensaktieindekset*</b>
                        </div>
                    </Grid.Column>
                    <Grid.Column style={{textAlign: 'center'}}>
                        <div><img src={Bullet2} className="bullet"/></div>
                        <div><b>Frem mod 2025 er ambitionen, at hele produktet skal være CO<sub>2</sub> neutralt**.</b>
                        </div>
                    </Grid.Column>
                    <Grid.Column style={{textAlign: 'center'}}>
                        <div><img src={Bullet3} className="bullet"/></div>
                        <div><b>Frem mod 2030 er ambitionen, at hele produktet skal være CO<sub>2</sub> negativt**, og
                            dermed
                            trække mere CO<sub>2</sub> ud af atmosfæren, end det udleder.</b>
                        </div>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
            <p style={{fontStyle: 'italic', fontSize: 13}}>* MSCI All Countries World Index.<br/>** Søges opfyldt gennem
                investering i skov og teknologi, der tager CO<sub>2</sub> ud af atmosfæren</p>
        </Segment>

        <Divider hidden/>

        <Segment basic>
            <h3>Sådan sikrer PFA klimamålene</h3>
            <p>For at sikre, at PFA holder sig indenfor klimamålene og samtidig arbejder for det bedst mulige afkast, så måler PFA løbende CO<sub>2</sub>-udledningen på porteføljer og sammenligner med verdensindekset. Som modellen viser, udleder aktierne i ekstra klimavenlige aktier 75 % mindre CO<sub>2</sub> end verdensindekset, mens alle PFA's aktieinvesteringer pt. udleder 42 % mindre CO<sub>2</sub>.</p>

            <Grid centered stackable columns="one" divided>
                <Grid.Row>
                    <Grid.Column style={{textAlign: 'center'}}>
                        <div><Image src={KlimaBanner}/></div>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Segment>

        <Segment basic style={{backgroundColor: '#f6f0e7'}}>
            <h3>Et bedre klima er vi fælles om</h3>
            <p>Klimavenlige investeringer kan være vindmøller og solenergi, men det kan også meget andet. Mange både store og små virksomheder i stort set alle brancher har fokus på deres klimaaftryk, og det arbejder de med på vidt forskellige måder. PFA investerer både i virksomheder og projekter, og her kan du se et udpluk af de investeringer, der er med i ekstraklimavenlige investeringer:</p>
            <Grid centered stackable columns="two" divided>
                <Grid.Row>
                    <Grid.Column style={{textAlign: 'center', padding: 20}}>
                        <div><img src={RepublicLogo} className="klima-logo"/></div>
                        <div>Republic Services Inc. er det amerikanske renovationsselskab, der er længst fremme i den
                            grønne
                            omstilling, når det gælder om at genbruge affald eller omdanne det til energi. Selskabet
                            håndterer ikke-farligt affald fra private og virksomheder, og de har fokus på miljøvenlig
                            håndtering og skånsom transport. Derfor kører deres biler i stigende grad på naturgas og
                            ikke
                            benzin.
                        </div>
                    </Grid.Column>
                    <Grid.Column style={{textAlign: 'center', padding: 20}}>
                        <div><img src={NidecLogo} className="klima-logo-nidec"/></div>
                        <div>Nidec Corp er et japansk industriselskab, som investerer massivt i at etablere en ledende position som leverandør af motorer til elektriske biler (EV). Selskabet producerer en række forskellige kategorier af elektriske motorer bl.a. til harddiske og servostyring i biler. Nidec har ikke bare stærke relationer til bilindustrien, men også lang historik indenfor omkostningslederskab og innovation.
                        </div>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Segment>

        <Divider hidden/>

        <Segment basic>
            <h3>Sammenligning af opsparingsmuligheder</h3>
            <p>Klimastærke investeringer skal ikke koste på afkastet. Derfor er risikoniveauet og forventningerne til afkastet i ekstra klimavenlige investeringer de samme som i PFA’s øvrige markedsrenteløsning. Dog vil afkastet udvikle sig forskelligt, fordi produkterne er investeret forskelligt.</p>
            <p>Mange af investeringerne med ekstra fokus på klima indgår også i de øvrige investeringer, men ikke nødvendigvis omvendt. Det skyldes de ekstra høje klimakrav, som kun et begrænset antal selskaber kan leve op til. Ekstra klimavenlige investeringer indeholder derfor langt færre selskaber, og det kan give større udsving i afkastet især på den korte bane, fordi der er færre investeringer til at balancere hinanden. Men pension er langsigtet opsparing, og som udgangspunkt får du samme risikoniveau og forventede afkastpotentiale ved at investere din opsparing mere klimavenligt.</p>

            <Table color="teal" inverted celled unstackable>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>Parametre</Table.HeaderCell>
                        <Table.HeaderCell textAlign="center">Investering i Letsikring</Table.HeaderCell>
                        <Table.HeaderCell textAlign="center">Investering i Letsikring med ekstra fokus på klima</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>

                <Table.Body>
                    <Table.Row>
                        <Table.Cell>Politik for ansvarlige investeringer og aktivt ejerskab</Table.Cell>
                        <Table.Cell textAlign="center"><Icon name="checkmark"/></Table.Cell>
                        <Table.Cell textAlign="center"><Icon name="checkmark"/></Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell>Ekstra klimafokus</Table.Cell>
                        <Table.Cell></Table.Cell>
                        <Table.Cell textAlign="center"><Icon name="checkmark"/></Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell>Eksklusion af fossile brændstoffer</Table.Cell>
                        <Table.Cell></Table.Cell>
                        <Table.Cell textAlign="center"><Icon name="checkmark"/></Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell>CO<sub>2</sub> emission ift. Verdensaktieindekset</Table.Cell>
                        <Table.Cell textAlign="center">-40 %</Table.Cell>
                        <Table.Cell textAlign="center">-75 %</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell>Målsætning om CO<sub>2</sub> neutral i år</Table.Cell>
                        <Table.Cell textAlign="center">2050</Table.Cell>
                        <Table.Cell textAlign="center">2025</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell>Alternative investeringer og ejendomme</Table.Cell>
                        <Table.Cell textAlign="center">Ca. 23 % pt</Table.Cell>
                        <Table.Cell textAlign="center">Ca. 20 % over tid</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell>Risiko</Table.Cell>
                        <Table.Cell colSpan={2} textAlign="center">Sammenlignelig risiko</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell>Forventet afkast </Table.Cell>
                        <Table.Cell colSpan={2} textAlign="center">Samme forventede langsigtede afkast</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell>Investeringsomkostninger</Table.Cell>
                        <Table.Cell textAlign="center">0,36-0,75 %</Table.Cell>
                        <Table.Cell textAlign="center">0,41-0,78 %</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell>Nedtrapning af risiko frem mod pension</Table.Cell>
                        <Table.Cell textAlign="center"><Icon name="checkmark"/></Table.Cell>
                        <Table.Cell textAlign="center"><Icon name="checkmark"/></Table.Cell>
                    </Table.Row>
                </Table.Body>
            </Table>

        </Segment>

        <Divider hidden/>

        <Segment basic style={{backgroundColor: '#f6f0e7'}}>
            <h3>Derfor kan din pension gøre en forskel</h3>
            <p>Sammenlagt har danskerne sparet mange milliarder op i deres pensioner. Netop derfor har pensionsselskaber
                en
                vigtig rolle, når det kommer til at påvirke samfund og virksomheder i en mere klimavenlig retning. PFA
                forvalter ca. 585 milliarder kr. (opgjort pr. september 2023) og kan derfor opnå stor
                indflydelse.
                PFA
                har indflydelse i kraft af de selskaber, de vælger at investere i, hvor PFA bl.a. på generalforsamlinger
                påvirker selskaberne. </p>
        </Segment>

    </div>
);

export default Forside;
